<template>
  <div id="indexreturn">
    <div style="text-align: center;padding-top: 10%">
      <span class="text_4d">还书</span>
    </div>
    <div class="section_2d flex-col">
      <div class="text-wrapper_2d flex-row">
        <div style="width: 30%;height: 22px;margin: 15px 0 0 4%;"><span class="text_5d">书名</span></div>
        <!--        <div style="width: 19%;height: 22px;margin: 15px 0 0 0;"><span class="text_6d">条码</span></div>-->
        <div style="width: 30%;height: 22px;margin: 15px 0 0 0;"><span class="text_7d">借书日期</span></div>
        <div style="width: 25%;height: 22px;margin: 15px 0 0 0;"><span class="text_7d">状态信息</span></div>
      </div>
      <div id="returnbookcontent">
        <div class="text-wrapper_3d flex-row" v-for="(item,index) in books" :key="item.StrID">
          <div style="width: 30%;height: 47px;margin-left: 4%;text-align: left;display: flex;">
            <!--          <p class="text_8d2 ellipsis2" style="margin: 0" v-if="item.StrPositiveTitle.length<7">-->
            <!--            {{ item.StrPositiveTitle }}</p>-->
            <p class="text_9d2" style="margin: 0;display: flex;align-items: center;"
               v-if="item.StrPositiveTitle.length<18">{{ item.StrPositiveTitle }}</p>
            <p class="text_9d2" style="margin: 0;display: flex;align-items: center;" v-else>
              {{ item.StrPositiveTitle.substring(0, 18) }}...</p>
            <!--          <div class="text_9d2 ellipsis2"></div>-->
          </div>

          <!--        <div style="width: 20%;height: 47px;"><span class="text_9d2">{{ item.StrISBN }}</span></div>-->
          <div style="width: 30%;height: 47px;"><span class="text_8d2">{{ item.DTBorrowDate.substring(0, 10) }}</span>
          </div>
          <div style="width: 25%;height: 47px;display: flex;">
            <p class="text_9d2" style="margin: 0;display: flex;align-items: center;"
               v-if="item.StrReaderName.length<10">{{ item.StrReaderName }}</p>
            <p class="text_9d2" style="margin: 0;display: flex;align-items: center;" v-else>
              {{ item.StrReaderName.substring(0, 10) }}...</p>
          </div>
          <!--        isReturn被我用来做是否选中的标记了-->
          <div style="height: 47px;" @click="clickitem(index,item.StrType)" v-if="item.StrType==='false'">
            <img src="../assets/img/icon/choosebackup.svg" style="width: 30px;height: 30px;margin-top: 8.5px;">
          </div>
          <div style="height: 47px;" @click="clickitem(index,item.StrType)" v-else-if="item.StrType==='true'">
            <img src="../assets/img/icon/choose.svg" style="width: 30px;height: 30px;margin-top: 8.5px;">
          </div>
          <div style="height: 47px;" v-else-if="item.StrType==='noshow'"></div>
        </div>
        <div style="height: 37px;font-size: 12px;margin: auto;color: #C0C4CC;text-align: center;line-height: 37px"
             v-show="nodata">
          暂无数据
        </div>
        <div style="height: 37px;font-size: 12px;margin: auto;color: #C0C4CC;text-align: center;line-height: 37px"
             v-show="loading">
          加载中...
        </div>
      </div>
    </div>

    <button class="button_1d flex-col" @click="returnbook" v-if="canreturnbook">
      <!--      续借-->
      <div class="text_18d">还书</div>
    </button>

    <button class="button_1dx flex-col" v-else>
      <!--      续借-->
      <div class="text_18dx">还书</div>
    </button>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'returnbook',
  data () {
    return {
      books: [],
      barCodes: {
        'bookBarCodes': []
      },
      canreturnbook: false,
      nodata:false,
      loading:true
    }
  },
  mounted () {
    document.getElementById('indexreturn').style.height = document.body.clientHeight + 'px'
    //10% 6% 85px 6%
    document.getElementById('returnbookcontent').style.maxHeight = (document.body.clientHeight * 0.92) - (document.body.clientWidth * 0.22) - 136 + 'px'
    this.getbooks()
  },
  methods: {
    clickitem (index, is) {
      if (is === 'true') {
        this.books[index].StrType = 'false'
      } else if (is === 'false') {
        this.books[index].StrType = 'true'
      }

      var i
      for (i in this.books) {
        if (this.books[i].StrType === 'true') {
          this.canreturnbook = true
          return
        }
      }
      this.canreturnbook = false
    },
    getbooks () {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL+'/sanyue/currentBorrow',
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        if (response.code === 0) {
          this.books = response.msg
          var index
          for (index in this.books) {
            //StrReaderName 被用来标记状态信息了
            this.books[index].StrReaderName = ''
            this.books[index].StrType = 'false'
          }
          if(this.books.length === 0){
            this.nodata = true
          }
          this.loading = false
          // document.getElementById('libitems').setAttribute('max-length', 3 * 51 +'px')
        }else {
          alert(response.msg)
        }
      })
    },
    returnbook () {
      this.barCodes.bookBarCodes = []
      let index
      for (index in this.books) {
        if (this.books[index].StrType === 'true') {
          this.barCodes.bookBarCodes.push(this.books[index].StrBookBarcode)
        }
      }
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL+'/sanyue/return',
        data: JSON.stringify(this.barCodes),
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        if (response.code === 0) {
          // this.$notify({
          //   title: '成功',
          //   message: '还书成功',
          //   type: 'success',
          //   duration: 2000
          // })
          var allsuccess = true
          var index, bookindex
          for (index in response.msg) {
            for (bookindex in this.books) {
              if (response.msg[index].bookBarCode === this.books[bookindex].StrBookBarcode) {
                if (response.msg[index].success) {
                  this.books[bookindex].StrReaderName = '还书成功'
                  this.books[bookindex].StrType = 'noshow'
                } else {
                  this.books[bookindex].StrReaderName = response.msg[index].msg
                  //将全部都不选
                  this.books[bookindex].StrType = 'false'
                  allsuccess = false
                }
              }
            }
          }
          this.canreturnbook = false
          if (allsuccess) {
            this.$notify({
              title: '成功',
              message: '还书成功',
              type: 'success',
              duration: 2000
            })
          }
          // document.getElementById('libitems').setAttribute('max-length', 3 * 51 +'px')
        } else {
          this.$notify.error({
            title: '错误',
            message: '还书失败,' + response.msg.msg,
            duration: 2000
          })
        }
      })
    },
  }
}
</script>

<style scoped>
#indexreturn {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#returnbookcontent {
  overflow-y: auto;
  overflow-x: hidden
}

/*超出长度显示省略号*/
.ellipsis {
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_4d {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
}

.section_2d {
  background-color: rgba(255, 255, 255, 1);
  /*border-radius: 0 0 12px 12px;*/
  border-radius: 12px;
  position: relative;
  width: 90%;
  margin: 6% 0 0 5%;
}

.text-wrapper_2d {
  background-color: rgba(237, 242, 255, 1);
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 52px;
  /*margin: 6% 0 0 5%;*/
  /*justify-content: flex-center;*/
}

.text_5d {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;


}

.text_6d {

  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_7d {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text-wrapper_3d {
  width: 100%;
  height: 47px;
  /*margin-left: 2%;*/
}

.text_8d2 {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 47px;
  width: 90%;
  margin: 0;
}

.text_9d2 {
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
  text-align: left;
  /*margin: 0;*/
  width: 90%;
  overflow: hidden;
  line-height: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin: 0;
}


.button_1d {
  background-color: rgba(0, 94, 255, 0.1);
  border-radius: 8px;
  height: 51px;
  width: 90%;
  margin: 0 0 0 5%;
  position: absolute;

  bottom: 8%;
}

.text_18d {
  color: rgba(0, 94, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 15px;

  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
}

.button_1dx {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 51px;
  width: 90%;
  margin: 0 0 0 5%;
  position: absolute;

  bottom: 8%;
}

.text_18dx {
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 15px;

  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
}
</style>
