<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'
import { getAccess, getUserInfo } from '@/api2'

export default {
  name: 'app',
  data () {
    return {

    }
  },
  created () {
    //可借图书、00000001~00000008
    //获取AccessToken的方法
    // http://dev.sanyue100.com/wx/e2f2991a1fd519e0
    //https://wx.sanyue100.com/e2f2991a1fd519e0
    // console.log(new Date().valueOf())
    // localStorage.setItem('token','Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFeHBpcmUiOjE2Nzk0OTE5OTcsIm5pY2tuYW1lIjoiV2FycmlvciIsInN0cklkIjoiYjBmYWJjMGEzODhjNDRjZWJiN2Q3ZDY5NjYyMTU3MzMifQ.Jb1KJFBgcCASFiKhhpBBfqaLuAntPKFNDvWcPCyolgU')
    // localStorage.setItem('tokentime',new Date().getTime()+'')
    getUserInfo();
  },
  methods: {
  }
}
</script>
<style lang="less">

</style>
