<template>
  <div id="indexbind">
    <div style="text-align: center;padding-top: 10%">
      <span class="text_4h">绑定读者证</span>
    </div>

    <div class="group_3h flex-col">
      <div class="section_1h flex-row justify-between">
        <span class="text_5h">馆名称</span>
        <div class="box_1h flex-row justify-between" @click="dropdown">
          <span class="text_6h">下拉选择一个</span>
          <img
            class="label_1h"
            referrerpolicy="no-referrer"
            src="../assets/img/icon/downarrow.svg"/>
          <div class="block_3h flex-col" v-show="dropdownitemshow" id="libitems" >
            <div v-for="(item,index) in this.libs" :key="item.StrID" @click="clicklib(item.StrName,item.StrID)">
              <el-divider v-show="index!==0"/>
              <div style="padding: 15px 0"><span class="text_12h">{{ item.StrName }}</span></div>
<!--              <img-->
<!--                class="image_3h"-->
<!--                referrerpolicy="no-referrer"-->
<!--                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga4b02a0cb593d1007acebc62e3956ce90255544459d8975bce83ebcf7f59e34a"-->
<!--              />-->
            </div>

          </div>
        </div>
      </div>

      <div class="section_2h flex-row justify-between">
        <span class="text_7h">读者证号</span>
        <input class="text-wrapper_1h flex-col" placeholder="请输入读者证号" @input="inputreadercard" maxlength="12" type="email" id="inputCard"/>
        <!--          <span class="text_8h">请输入读者证号</span>-->
        <!--        <div class="text-wrapper_1h flex-col">-->
        <!--          <span class="text_8h">请输入读者证号</span>-->
        <!--        </div>-->
      </div>
    </div>
    <!--    <button class="button_1h flex-col">-->
    <!--      &lt;!&ndash;      续借&ndash;&gt;-->
    <!--      <div class="text_18h">绑定</div>-->
    <!--    </button>-->
<!--    <button class="text-wrapper_2h flex-col"  v-if="havecard&&havelib" @click="bindClick" >-->
<!--      <span class="text_9h">绑定</span>-->
<!--    </button>-->

    <button class="text-wrapper_2h flex-col"  v-if="havelib&&havecard" @click="bindClick" >
      <span class="text_9h">绑定</span>
    </button>
    <button class="button_1h2 flex-col" v-else>
      <!--      续借-->
      <div class="text_18h2">绑定</div>
    </button>
  </div>
</template>

<script>
import axios from 'axios'
import { getUrlVariable, getUserInfo } from '@/api2'
import {bind} from "lodash/function";

export default {
  name: 'bind',
  data () {
    return {
      dropdownitemshow: false,
      bindmsg: {
        "activateTicket": "",
        "cardId": "",
        "encryptCode": "",
        "libCode": "",
        "libName": "",
        "openid": "",
        "readerCardNum": ""
      },
      libs: [],

      havelib: false,
      havecard: false,

      binding: false
    }
  },
  mounted () {
    document.getElementById('indexbind').style.height = document.body.clientHeight + 'px'
    this.bindmsg.activateTicket = getUrlVariable('activate_ticket')
    this.bindmsg.openid = JSON.parse(localStorage.getItem('user')).wxOpenId
    this.bindmsg.encryptCode = getUrlVariable('encrypt_code')
    this.bindmsg.cardId = getUrlVariable('card_id')
    this.getAllLibs()
  },
  methods: {
    bindClick(){
      // alert(bindmsg.libCode)
      if(this.binding){
        return
      }
      console.log(this.bindmsg.openid)
      this.binding = true
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL+'/wx/activeWxMemberCard',
        data: JSON.stringify(this.bindmsg),
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        this.binding = false
        if (response.code === 0) {
          this.$notify({
            title: '成功',
            message: '绑定成功',
            type: 'success',
            duration: 1000
          });
          getUserInfo()
          this.$router.push({
            path: 'index'
          })
        }
        else{
          this.$notify.error({
            title: '错误',
            message: '绑定失败'+response.msg,
            duration: 2000
          });
        }
      })
    },
    getAllLibs () {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL+'/sanyue/getAllLibs',
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        if (response.code === 0) {
          this.libs = response.msg
          // document.getElementById('libitems').setAttribute('max-length', 3 * 51 +'px')
        }
      })
    },
    dropdown () {
      if (this.dropdownitemshow) {
        this.dropdownitemshow = false
        document.getElementsByClassName('label_1h')[0].setAttribute('src', require('../assets/img/icon/downarrow.svg'))
      } else {
        this.dropdownitemshow = true
        document.getElementsByClassName('label_1h')[0].setAttribute('src', require('../assets/img/icon/uparrow.svg'))
      }
    },
    clicklib(name,id){
      document.getElementsByClassName('text_6h')[0].innerHTML=name
      this.bindmsg.libName = name
      this.bindmsg.libCode = id
      this.havelib = true
    },
    inputreadercard(){
      this.bindmsg.readerCardNum = document.getElementById('inputCard').value
      if(this.bindmsg.readerCardNum !== ''){
        this.havecard = true
      }else{
        this.havecard = false
      }
    }
  },
}
</script>

<style>
#indexbind {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  /*margin: 0 auto;*/
}
.el-notification {
  display: flex;
  width: 80% !important;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #EBEEF5;
  position: fixed;
  background-color: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
  overflow: hidden;
}
/*超出长度显示省略号*/
.ellipsis {
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}

.el-divider--horizontal{
  margin: 0 !important;
}
.el-divider {
  background-color: #eceef1 !important;
}

.block_3h {
  background-color: rgba(245, 249, 255, 1);
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  max-height: 204px;
  overflow: auto;
  /*height: 152px;*/
}

.text_10h {
  width: 84px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 15px 0 0 10px;
}

.image_2h {
  width: 100%;
  height: 1px;
  margin-top: 15px;
}

.text_11h {
  width: 98px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 15px 0 0 10px;
}

.image_3h {
  width: 100%;
  height: 1px;
}

.text_12h {
  width: 100%;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  /*margin: 0 0 15px 10px;*/
  margin-left: 10px;
}

.text_4h {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
}

.group_3h {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  /*height: 141px;*/
  width: 90%;
  margin: 21px 0 0 5%;
}

.section_1h {
  width: 90%;
  height: 48px;
  margin: 15px 0 0 15px;
}

.text_5h {
  width: 64px;
  /*height: 22px;*/
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 16px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  /*margin-top: 13px;*/
}

.box_1h {
  position: relative;
  background-color: rgba(245, 249, 255, 1);
  border-radius: 6px;
  width: 75%;
  height: 46px;
  border: 1px solid rgba(189, 199, 255, 1);
  margin-left: 7px;
}

.text_6h {
  width: 84px;
  /*height: 48px;*/
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 47px;
  margin: 0 0 0 10px;
}

.label_1h {
  width: 30px;
  height: 30px;
  margin: 9px 10px 0 0;
}

.section_2h {
  width: 90%;
  height: 48px;
  margin: 15px 0 15px 15px;
}

.text_7h {
  /*width: 64px;*/
  /*height: 22px;*/
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 16px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  /*margin-top: 13px;*/
}

.text-wrapper_1h {
  background-color: rgba(245, 249, 255, 1);
  border-radius: 6px;
  height: 46px;
  font-size: 14px;
  border: 1px solid rgba(189, 199, 255, 1);
  width: 75%;
  margin-left: 7px;
  padding-left: 10px;
}

.text_8h {
  width: 98px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14pt;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 14px 0 0 10px;
}

input {
  outline: none;
}

.button_1h {
  background-color: rgba(0, 94, 255, 0.1);
  border-radius: 8px;
  height: 51px;
  width: 90%;
  margin: 0 0 0 5%;
  position: absolute;

  bottom: 8%;
}

.text_18h {
  color: rgba(0, 94, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 15px;

  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
}

.button_1h2 {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 51px;
  width: 90%;
  margin: 0 0 0 5%;
  position: absolute;

  bottom: 8%;
}

.text_18h2 {
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 15px;

  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
}


.text-wrapper_2h {
  background-color: rgba(0, 94, 255, 0.1);
  border-radius: 8px;
  height: 51px;
  width: 90%;
  margin: 0 0 0 5%;
  position: absolute;

  bottom: 8%;
}

.text_9h {
  color: rgba(0, 94, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 15px;

  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
}
</style>
