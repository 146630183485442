<template>
  <div id="indexmy">
    <div class="text-wrapper_1my flex-col">
      <div class="section_5my flex-col" :style="{backgroundImage:'url('+userInfo.wxHeaderImgUrl+')'}"></div>
      <span class="text_4my">{{ userInfo.nickname }}</span>
    </div>
    <!--    <div style="text-align: center;padding-top: 10%">-->
    <!--&lt;!&ndash;      <span class="text_4c">我的</span>&ndash;&gt;-->
    <!--      <span class="text_4my">我的</span>-->
    <!--    </div>-->
    <div class="section_2my" @click="readerCard">
      <div class="text-wrapper_2my">
        <!--        <span class="text_5my">我的读者证</span>-->
        <span class="text_5my">图书馆：{{userInfo.libName}}</span>
      </div>
      <div class="section_4my justify-between">
        <!--        <span class="text_6my">{{ userInfo.readerCardNum }}</span>-->
        <span class="text_6my">读者证：{{ userInfo.readerCardNum }}</span>
        <!--        <img-->
        <!--          class="icon_1my"-->
        <!--          referrerpolicy="no-referrer"-->
        <!--          src="../assets/img/icon/arrowgo.svg"-->
        <!--        />-->
      </div>
    </div>
    <div class="section_3my flex-row" @click="myread">
      <div class="image-text_2my flex-row justify-between">
        <img
          class="icon_2my"
          referrerpolicy="no-referrer"
          src="../assets/img/icon/read.svg"/>
        <span class="text-group_1my">我的阅读</span>
      </div>
      <img
        class="label_2my"
        referrerpolicy="no-referrer"
        src="../assets/img/icon/arrowgo2.svg"
      />
    </div>

    <!-- 添加 el-dialog 组件 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      height="60%"
      @close="handleClose"
    >
      <template #title>
        <!-- 在标题前添加一个图标 -->
        <img src="../assets/img/icon/tubiao.svg" alt="icon" style="width: 40px; height: 40px; margin-right: 8px;"><span
        class="art-font">   微信读者证</span>
      </template>
      <el-row>
        <!--          <el-card :body-style="{ padding: '0px' }">-->
        <!--            <img :src="imgUrl" class="image">-->
        <!--            <vue-qr :logoSrc="imageUrl" :text="openId" :style="{height:'100%',width:'100%'}" :size="300"></vue-qr>-->
        <vue-qr :text="openId" :style="{height:'100%',width:'100%'}" :size="300"></vue-qr>

        <div style="padding: 14px;">
          <!--              <span>好吃的汉堡</span>-->
          <div class="bottom clearfix">
            <!--                <el-button type="text" class="button">操作按钮</el-button>-->
          </div>
        </div>
        <!--          </el-card>-->
      </el-row>
    </el-dialog>
    <!--    <div class="section_2q flex-col">-->
    <!--      <div class="text-wrapper_2q flex-row">-->
    <!--        <span class="text_5q">我的读者证</span>-->
    <!--      </div>-->
    <!--      <div class="section_4q flex-row justify-between">-->
    <!--        <span class="text_6q">28882992929</span>-->
    <!--        <img-->
    <!--          class="icon_1q"-->
    <!--          referrerpolicy="no-referrer"-->
    <!--          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaf79c39d5b1dc7c5de980f8abcf83fb87205a977dc2c326bcabcb644356cf24b"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import axios from 'axios'
import vueQr from 'vue-qr'
import $ from 'jquery'
import {gettoken, loginfirst, getAccess, getUrlVariable, getUserInfo} from '@/api2'

export default {
  name: 'My',
  data() {
    return {
      constants: {},
      userInfo: {},
      dialogVisible: false,
      imgUrl: '',
      // imageUrl: require("../assets/img/icon/tubiao.svg"),
      imageUrl: JSON.parse(localStorage.getItem('user')).wxHeaderImgUrl,
      openId: '',
    };
  },
  mounted() {
    document.getElementById('indexmy').style.height = document.body.clientHeight * 0.88 + 'px'
    document.getElementsByClassName('section_2my')[0].style.height = document.body.clientWidth * 0.88 * 0.331 + 'px'
    this.readerMsg()
  },
  methods: {
    readerMsg() {
      this.userInfo = JSON.parse(localStorage.getItem('user'))
      // axios({
      //   method: 'POST',
      //   url: baseURL+'user/userInfo',
      //   //发送格式为json
      //   headers:
      //     {
      //       'Content-Type': 'application/json',
      //       'dataType': 'json',
      //       'Authorization': localStorage.getItem('token')
      //     }
      // }).then(response => {
      //   if (response.code === 0) {
      //     this.userInfo = response.msg
      //     // this.books = response.msg
      //   }
      // })
    },
    myread() {
      this.$router.push({
        path: 'myread'
      })
    },
    readerCard() {
      this.dialogVisible = true;
      // window.location.href = ''
      if (JSON.parse(localStorage.getItem('user')).wxOpenId === '' || JSON.parse(localStorage.getItem('user')).wxHeaderImgUrl === '') {
        const code = getUrlVariable('code')
        const state = getUrlVariable('state')
        loginfirst()
        gettoken(code, state)
        next()
      } else {
        this.imgUrl = 'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png';
        this.openId = JSON.parse(localStorage.getItem('user')).wxOpenId;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    }
    // bindreadcard(){
    //   this.$router.push({]\

    //     path: 'bind'
    //   })
    // }
  },
  components: {
    vueQr
  },
}
</script>
<style src="../assets/css/common.css"/>
<style src="../assets/css/index_my.css"/>
<style scoped>
#indexmy {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}


.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}


/* 首先，链接Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.art-font {
  font-family: 'Microsoft YaHei', '微软雅黑', 'sans-serif';
  font-size: 18px; /* 调整字体大小 */
  color: #191970; /* 调整字体颜色 */
  text-shadow: 2px 2px 4px #aaa; /* 添加阴影效果 */
}

</style>
