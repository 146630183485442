<template>
  <div id="indexborrow">
    <div style="text-align: center;padding-top: 10%">
      <span class="text_4f">借书</span>
    </div>
    <div class="box_2f2 flex-col">
      <div class="image-text_1f2 flex-row justify-between">
        <img
          class="label_1f2"
          referrerpolicy="no-referrer"
          src="../assets/img/icon/record.svg"
        />
        <span class="text-group_1f2">记录</span>
      </div>
      <div class="list_1f2 flex-row">
        <div
          class="list-items_1f2 flex-col"
          style="background: rgba(238,236,255)">
          <div class="text-wrapper_1f2 flex-row justify-between">
            <div class="text_5f2">{{ readerInfo.IntBookBorrowCount }}</div>
            <div class="text_6f2">本</div>
          </div>
          <span class="text_7f2">借阅数量</span>
        </div>
        <div
          class="list-items_1f2 flex-col"
          style="background: rgba(236,251,255)">
          <div class="text-wrapper_1f2 flex-row justify-between">
            <div class="text_5f2">{{ readerInfo.IntBookOverdueCount }}</div>
            <div class="text_6f2">本</div>
          </div>
          <span class="text_7f2">超期数量</span>
        </div>
        <div class="list-items_1f2 flex-col"
             style="background: rgba(255,236,236)">
          <div class="text-wrapper_1f2 flex-row justify-between">
            <div class="text_5f2">{{ readerInfo.IntBookCouldBorrowCount }}</div>
            <div class="text_6f2">本</div>
          </div>
          <span class="text_7f2">可借数量</span>
        </div>
      </div>
    </div>
    <!--    <div class="box_3f2 flex-row justify-between">-->
    <!--      <span class="text_8f2">请输入图书条码</span>-->
    <!--      <img-->
    <!--        class="label_2f2"-->
    <!--        referrerpolicy="no-referrer"-->
    <!--        src="../assets/img/icon/scan.svg"-->
    <!--      />-->
    <!--    </div>-->
    <div style="position: relative">
      <input class="box_3f2 flex-row justify-between text_8f2" placeholder="请输入图书条码" maxlength="12" type='email'
             @keyup.enter="borrowbooks" v-model="book.bookBarCode">
      <!--      <span class="">请输入图书条码</span>-->
      <img
        class="label_2f2"
        referrerpolicy="no-referrer"
        src="../assets/img/icon/scan.svg"
        @click="scaneMethod"/>
    </div>
    <div class="section_2f flex-col">
      <div class="text-wrapper_2f flex-row">
        <div style="width: 21%;height: 22px;margin: 15px 0 0 4%;"><span class="text_5f">书名</span></div>
        <div style="width: 22%;height: 22px;margin: 15px 0 0 0;"><span class="text_6f">条码</span></div>
        <div style="width: 27%;height: 22px;margin: 15px 0 0 0;"><span class="text_7f">还书日期</span></div>
        <div style="width: 20%;height: 22px;margin: 15px 0 0 0;"><span class="text_7f">状态信息</span></div>
      </div>
      <div id="borrowbookscontent">
        <div class="text-wrapper_3f flex-row" v-for="item in hasborrowbooks" :key="item.StrID">
          <div style="width: 23%;height: 47px;display: flex;">
            <p class="text_9f" style="margin: 0;display: flex;align-items: center;"
               v-if="item.StrPositiveTitle.length<13">{{ item.StrPositiveTitle }}</p>
            <p class="text_9f" style="margin: 0;display: flex;align-items: center;" v-else>
              {{ item.StrPositiveTitle.substring(0, 13) }}...</p>
          </div>
          <div style="width: 23%;height: 47px;"><span class="text_8f">{{ item.StrBookBarcode }}</span></div>
          <div style="width: 27%;height: 47px;">
            <span class="text_8f" v-if="item.DTReturnDate !== undefined">{{ item.DTReturnDate.substring(0, 10) }}</span>
            <span class="text_8f" v-else></span>
          </div>
          <div style="width: 25%;height: 47px;display: flex;">
            <p class="text_9f" style="margin: 0;display: flex;align-items: center;" v-if="item.StrBinding.length<13">
              {{ item.StrBinding }}</p>
            <p class="text_9f" style="margin: 0;display: flex;align-items: center;" v-else>{{ item.StrBinding }}...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import sha1 from 'sha1'
import $ from 'jquery'
import axios from 'axios'

export default {
  name: 'borrowbook',
  data () {
    return {
      readerInfo: {},
      book: {
        bookBarCode: ''
      },
      hasborrowbooks: [],
      hasbrorrow: '',
      currentborrow: '',

      confignoncestr: '',
      ticket: '',
      configtimestamp: '',
      url: '',
      configsignature: '',
      accesstoken: ''
    }
  },
  created () {
    this.judgeJsapiTicket()

    this.url = window.location.href.split('#')[0]
    this.configtimestamp = new Date().getTime()
    this.confignoncestr = this.randomStr(8)
    //$.ajax里面使用不了this
    this.ticket = localStorage.getItem('ticket')
    var string = 'jsapi_ticket='+this.ticket+'&noncestr='+this.confignoncestr+'&timestamp='+this.configtimestamp+'&url='+this.url
    // var string = 'jsapi_ticket=' + 'LIKLckvwlJT9cWIhEQTwfD0-lqQ5YXOLf7dU4t0j4jjupwfLuwEUnKb3z8XpAqWx2_bNNXuTk0AXzOptub5qUg' + '&noncestr=' + this.confignoncestr + '&timestamp=' + this.configtimestamp + '&url=' + this.url
    // console.log(string)
    this.configsignature = sha1(string)

    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: 'wx8c4d524f79af2234', // 必填，公众号的唯一标识
      timestamp: this.configtimestamp, // 必填，生成签名的时间戳
      nonceStr: this.confignoncestr, // 必填，生成签名的随机串
      signature: sha1(string),// 必填，签名
      jsApiList: ['scanQRCode'] // 必填，需要使用的 JS 接口列表
    })
  },
  mounted () {

    document.getElementById('indexborrow').style.height = document.body.clientHeight + 'px'
    //10% 33 20 132 10 60 10 52 合计w10% 317px
    document.getElementById('borrowbookscontent').style.maxHeight = document.body.clientHeight - (document.body.clientWidth * 0.15) - 317 + 'px'
    this.getReaderInfo()
  },
  methods: {
    judgeJsapiTicket () {
      $.ajax({
        url: process.env.VUE_APP_BASE_URL+'/wx/jsApiTicket', //url路径
        type: 'GET', //GET
        async: false, //或false,是否异步
        timeout: 10000, //超时时间
        dataType: 'json', //返回的数据格式：
        success: function (response) {
          if (response.code === 0) {
            localStorage.setItem('ticket',response.msg.ticket)
          }
        },
      })
    },
    // // 生成一个长度为length的随机字符串
    randomStr (length) {
      var str = ''
      for (var i = 0; i < length; i++) {
        var num = Math.floor(Math.random() * 26)
        str += String.fromCharCode(num + 97)
      }
      return str
    },
    scaneMethod () {
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (!isWeixin) {
        alert('请用微信打开连接，才可使用扫一扫')
      }
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res) => {
          // 扫码成功，跳转到二维码指定页面（res.resultStr为扫码返回的结果）
          //  location.href = res.resultStr;
          var scan = res.resultStr
          if(scan.indexOf(",") !==-1){
            scan=scan.split(',')[1]
          }

          this.book.bookBarCode = scan
          this.borrowbooks()
        },
        error: function (res) {
          if (res.errMsg.indexOf('function_not_exist') > 0) {
            alert('当前版本过低，请进行升级')
          }
        },
      })
    },
    getReaderInfo () {
      axios({
        method: 'POST',
        url:process.env.VUE_APP_BASE_URL+'/sanyue/readerInfo',
        // data: JSON.stringify(this.book),
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        if (response.code === 0) {
          this.readerInfo = response.msg
        } else {
          alert(response.msg)
          // console.log(response.msg)
        }
      })
    },
    borrowbooks () {
      if (this.book.bookBarCode === '') {
        this.$notify.info({
          title: '消息',
          message: '请输入图书条码',
          duration: 2000
        })
        return
      }
      //防止这次借书和上次借书条码一样，重复操作
      this.currentborrow = this.book.bookBarCode
      if (this.currentborrow === this.hasbrorrow) {
        this.$notify.info({
          title: '消息',
          message: '请不要重复借书',
          duration: 2000
        })
        return
      }
      axios({
        method: 'POST',
        url:process.env.VUE_APP_BASE_URL+'/sanyue/borrow',
        data: JSON.stringify(this.book),
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        this.getReaderInfo()
        this.hasbrorrow = this.book.bookBarCode
        if (response.code === 0) {
          this.$notify({
            title: '成功',
            message: '借书成功',
            type: 'success',
            duration: 2000
          })
          response.msg.book.StrBinding = '借书成功'
          this.hasborrowbooks.unshift(response.msg.book)
        } else {
          this.$notify.error({
            title: '错误',
            message: '借书失败,' + response.msg.msg,
            duration: 2000
          })
          if (response.msg.book !== null) {
            response.msg.book.StrBinding = '借书失败' + response.msg.msg
            this.hasborrowbooks.unshift(response.msg.book)
          }
        }
      })
    },
  },
}
</script>

<style>
#indexborrow {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#borrowbookscontent {
  overflow-y: auto;
  overflow-x: hidden
}

.el-notification {
  display: flex;
  width: 80% !important;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #EBEEF5;
  position: fixed;
  background-color: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
  overflow: hidden;
}

/*超出长度显示省略号*/
.ellipsis {
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}

.ellipsis2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.text_4f {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
}


.box_3f2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  width: 90%;
  height: 60px;
  margin: 10px 0 10px 5%;
  padding-left: 15px;
}

.text_8f2 {
  /*width: 112px;*/
  /*height: 22px;*/
  overflow-wrap: break-word;
  color: rgba(59, 59, 59, 1);
  font-size: 16px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  /*margin: 19px 0 0 15px;*/
}

.label_2f2 {
  width: 30px;
  height: 30px;
  /*margin: 15px 15px 0 0;*/

  position: absolute;
  right: 7%;
  top: 15px;
}

.box_2f2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  width: 90%;
  height: 132px;
  justify-content: flex-center;
  margin: 20px 0 0 5%;
}

.image-text_1f2 {
  width: 62px;
  height: 25px;
  margin: 15px 0 0 15px;
}

.label_1f2 {
  width: 25px;
  height: 25px;
}

.text-group_1f2 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 2px;
}

.list_1f2 {
  width: 90%;
  height: 62px;
  margin: 15px 0 15px 15px;
}

.list-items_1f2 {
  border-radius: 8px;
  position: relative;
  width: 31%;
  height: 62px;
  margin-right: 9px;
}

.text-wrapper_1f2 {
  width: 30px;
  height: 33px;
  margin: 25px 0 0 10px;
}

.text_5f2 {
  display: inline-block;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
}

.text_6f2 {
  display: inline-block;
  width: 14px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(95, 95, 95, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 9px;
}

.text_7f2 {
  position: absolute;
  left: 10px;
  top: 7px;
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(95, 95, 95, 1);
  font-size: 14px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}

.section_2f {
  background-color: rgba(255, 255, 255, 1);
  /*border-radius: 0 0 12px 12px;*/
  border-radius: 12px;
  position: relative;
  width: 90%;
  margin: 10px 0 0 5%;
}

.text-wrapper_2f {
  background-color: rgba(255, 247, 237, 1);
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 52px;
  /*margin: 6% 0 0 5%;*/
  /*justify-content: flex-center;*/
}

.text_5f {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;


}

.text_6f {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_7f {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}


.text-wrapper_3f {
  width: 100%;
  height: 47px;
  margin: 0 0 0 2%;
}

.text_8f {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 47px;
}

.text_9f {
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
  text-align: left;
  /*margin: 0;*/
  width: 90%;
  overflow: hidden;
  line-height: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin: 0;
}

input {
  outline: none;
}

</style>
