import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './plugins/element.js'
import store from './store'
// 导入全局样式表
import './assets/css/global.css'

// import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import './init.js';

require('dotenv').config()
//              !!!!!!!!!!!!!!!!!!!
// NODE_ENV = development  // 模式   ！！！记得要安装require('dotenv').config() 是一个 Node.js 模块调用的方法，它的作用是将 .env 文件中的变量加载到 Node.js 环境中，以便在程序中使用这些变量 在使用该方法之前，你需要先安装 dotenv 模块。可以使用 npm 来进行安装：npm install dotenv
// VUE_APP_MODE = development  // 通过"VUE_APP_MODE"变量来区分环境
// VUE_APP_BASE_URL = http://dev.sanyue100.com // api地址
// VUE_APP_CARD_URL = https://mp.weixin.qq.com/bizmall/activatemembercard?action=preshow&&encrypt_card_id=9MrYcU0aXLz04ox6B73zPbWN8g3MzhwV1N27DVLLv3HVka9MoSMUsjvhUwUurEgm&outer_str=123&biz=Mzg3Mjg5MTIxNw%3D%3D#wechat_redirect // CARD地址


router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  // document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

Vue.use(ElementUI)
// Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),

  router,
  store
}).$mount('#app')
