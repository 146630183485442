<template>
  <div id="indexmyread">
    <div style="text-align: center;padding-top: 10%">
      <span class="text_4e">我的阅读</span>
    </div>
    <!--    <ul class="infinite-list" v-infinite-scroll="load1" style="overflow:auto">-->
    <!--      <li v-for="i in count" class="infinite-list-item">{{ i }}</li>-->
    <!--    </ul>-->
    <div class="section_2e flex-col">
      <div class="text-wrapper_2e flex-row">
        <div style="width: 31%;height: 22px;margin: 15px 0 0 5%;"><span class="text_5e">书名</span></div>
        <div style="width: 36%;height: 22px;margin: 15px 0 0 0;"><span class="text_6e">借书日期</span></div>
        <div style="width: 23%;height: 22px;margin: 15px 0 0 0;"><span class="text_7e">还书日期</span></div>
      </div>
      <div v-infinite-scroll="load" id="myreadcontent" infinite-scroll-distance="1">
        <!--      <div  id="myreadcontent">-->
        <div class="text-wrapper_3e flex-row" v-for="(item) in books" :key="item.StrID">
          <div style="width: 31%;height: 47px;display: flex">
            <p class="text_9e" style="margin: 0;display: flex;align-items: center;"
               v-if="item.StrPositiveTitle.length<13">{{ item.StrPositiveTitle }}</p>
            <p class="text_9e" style="margin: 0;display: flex;align-items: center;" v-else>
              {{ item.StrPositiveTitle.substring(0, 13) }}...</p>
          </div>
          <div style="width: 36%;height: 47px;"><span class="text_8e">{{ item.DTBorrowDate.substring(0, 10) }}</span>
          </div>
          <div style="width: 23%;height: 47px;"><span class="text_8e">{{ item.DTReturnDate.substring(0, 10) }}</span>
          </div>
        </div>
        <div style="height: 37px;font-size: 12px;margin: auto;color: #C0C4CC;text-align: center;line-height: 37px"
             v-show="nodata">
          暂无数据
        </div>
        <div style="height: 37px;font-size: 12px;margin: auto;color: #C0C4CC;text-align: center;line-height: 37px"
             v-show="loading">
          加载中...
        </div>
      </div>
      <!--      分页，不用了-->
      <!--      <el-pagination-->
      <!--        background-->
      <!--        hide-on-single-page-->
      <!--        :pager-count="5"-->
      <!--        small-->
      <!--        :total="1000"-->
      <!--        :current-page="listQuery.Page"-->
      <!--        :page-size="listQuery.PerPage"-->
      <!--        @current-change="currPageChange"-->
      <!--        layout="prev, pager, next">-->
      <!--      </el-pagination>-->


      <!--      <div class="text-wrapper_3e flex-row">-->
      <!--        <div style="width: 31%;height: 47px;" class="ellipsis"><span class="text_8e">《西游记1sdfdsaf》</span></div>-->
      <!--        <div style="width: 36%;height: 47px;"><span class="text_8e">2023-01-15</span></div>-->
      <!--        <div style="width: 23%;height: 47px;"><span class="text_8e">2023-01-15</span></div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<style src="../assets/css/common.css"/>
<script>
import axios from 'axios'

export default {
  name: 'Myread',
  data () {
    return {
      books: [],
      listQuery: {
        'Page': 0,
        'PerPage': 10
      },
      total: 0,
      count: 10,
      nodata: false,
      loading: false,
      nomoredata: false
    }
  },
  mounted () {
    document.getElementById('indexmyread').style.height = document.body.clientHeight + 'px'
    //10% 6% 85px 6%
    document.getElementById('myreadcontent').style.maxHeight = document.body.clientHeight - (document.body.clientWidth * 0.25) - 85 + 'px'
  },

  methods: {
    load () {
      if (this.nomoredata || this.loading || this.nodata) {
        return
      }
      this.listQuery.Page += 1
      this.getbooks()
    },

    getbooks () {

      this.loading = true
      axios({
        method: 'POST',
        url:  process.env.VUE_APP_BASE_URL+'/sanyue/myReads',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem('token')
          }
      }).then(response => {
        if (response.code === 0) {
          this.loading = false
          this.books = this.books.concat(response.msg)

          if (response.msg.length < 10) {
            if(this.listQuery.Page === 1 && response.msg.length === 0){
              this.nodata = true
            }
            this.nomoredata = true
          }

        } else {
          alert(response.msg)
        }
      })
    }
  },
}
</script>

<style scoped>
#indexmyread {
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

#myreadcontent {
  overflow-y: auto;
  overflow-x: hidden;
}

/*超出长度显示省略号*/
.ellipsis {
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}

.text_4e {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(7, 7, 7, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
}

.section_2e {
  background-color: rgba(255, 255, 255, 1);
  /*border-radius: 0 0 12px 12px;*/
  border-radius: 12px;
  position: relative;
  width: 90%;
  margin: 6% 0 6% 5%;
}

.text-wrapper_2e {
  background-color: rgba(255, 247, 237, 1);
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 52px;
  /*margin: 6% 0 0 5%;*/
  /*justify-content: flex-center;*/
}

.text_5e {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;


}

.text_6e {

  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_7e {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text-wrapper_3e {
  width: 100%;
  height: 47px;
  margin: 0 0 0 5%;
}

.text_8e {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 47px;
}

.text_9e {
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
  text-align: left;
  /*margin: 0;*/
  width: 90%;
  overflow: hidden;
  line-height: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin: 0;
}

</style>
